<template>
  <div>
    <!-- 检索 -->
    <el-form :inline="true" :model="form" ref="ruleForm">
      <el-form-item class="form-item" label="部门名称" prop="name">
        <el-input
          v-model="form.name"
          placeholder="请输入部门名称"
          @keyup.enter="onSubmit"
        ></el-input>
      </el-form-item>
      <el-form-item class="form-item" label="部门状态" prop="status">
        <el-select v-model="form.status" placeholder="全部" clearable>
          <el-option label="待上线" value="1"></el-option>
          <el-option label="上线" value="2"></el-option>
          <el-option label="下线" value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="form-item" label="审核状态" prop="audit_status">
        <el-select v-model="form.audit_status" placeholder="全部" clearable>
          <el-option label="待审核" value="1"></el-option>
          <el-option label="审核不通过" value="2"></el-option>
          <el-option label="审核通过" value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="form-item form-submit">
        <el-button type="primary" @click="onSubmit">查询</el-button>
        <el-button @click="resetForm('ruleForm')">重置</el-button>
      </el-form-item>
    </el-form>
    <!-- 检索 -->

    <div class="table-content">
      <div class="table-title">
        <div>部门列表</div>
        <el-button
          v-if="type.indexOf('添加') > -1"
          type="primary"
          @click="handPartAdd"
          >添 加</el-button
        >
      </div>
      <!-- 列表 -->
      <el-table
        :data="tableData"
        border
        tooltip-effect="dark"
        style="width: 100%"
        v-loading="loading"
        element-loading-text="加载中"
        element-loading-spinner="el-icon-loading"
      >
        <el-table-column
          fixed="left"
          prop="name"
          label="部门名称"
          width="200"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="status"
          label="部门状态"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="created_at"
          label="创建时间"
          width="350"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="audit_status"
          label="审核状态"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          show-overflow-tooltip
          min-width="200"
          align="center"
        >
          <template #default="scope">
            <el-button
              v-if="type.indexOf('编辑') > -1"
              @click="handleClick(scope.$index)"
              type="text"
              size="small"
              >编辑</el-button
            >
            <el-button
              type="text"
              size="small"
              v-if="
                type.indexOf('审核') > -1 && scope.row.audit_status === '待审批'
              "
              @click="handAudit(scope.$index)"
              >审核</el-button
            >
            <el-button
              type="text"
              size="small"
              v-if="
                type.indexOf('上线/下线') > -1 &&
                scope.row.audit_status === '审批通过'
              "
              @click="handUpAndDown(scope.$index)"
              >{{ scope.row.status === "上线" ? "下线" : "上线" }}</el-button
            >
            <el-button
              v-if="
                type.indexOf('删除') > -1 && scope.row.audit_status === '待审批'
              "
              size="small"
              type="text"
              @click="handPartDelete(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 列表 -->

      <!-- 分页 -->
      <Pagination
        :pageSize="pageSize"
        :currentPage="currentPage"
        :total="total"
        @handSizeChange="handSizeChange"
        @handCurrentChange="handCurrentChange"
      ></Pagination>
      <!-- 分页 -->

      <!-- 添加/编辑 -->
      <el-dialog
        :title="isAdd ? '添加部门' : '编辑部门'"
        width="500px"
        v-model="isShow"
      >
        <el-form
          ref="partForm"
          :rules="rules"
          :model="partForm"
          label-width="100px"
        >
          <el-form-item label="部门名称：" prop="name">
            <el-input
              v-model="partForm.name"
              placeholder="请输入部门名称"
            ></el-input>
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="isShow = false">取 消</el-button>
            <el-button type="primary" @click="handPartSubmit('partForm')"
              >确 定</el-button
            >
          </span>
        </template>
      </el-dialog>
      <!-- 添加/编辑 -->

      <!-- 审核 -->
      <el-dialog title="审核部门" width="500px" v-model="audit">
        <el-form
          ref="auditForm"
          :rules="auditRules"
          :model="auditForm"
          label-width="100px"
        >
          <el-form-item label="审核：" prop="audit_status">
            <el-radio-group v-model="auditForm.audit_status">
              <el-radio label="2">审核不通过</el-radio>
              <el-radio label="3">审核通过</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="备注：">
            <el-input
              type="textarea"
              v-model="auditForm.audit_remarks"
            ></el-input>
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="audit = false">取 消</el-button>
            <el-button type="primary" @click="handAuditSubmit('auditForm')"
              >确 定</el-button
            >
          </span>
        </template>
      </el-dialog>
      <!-- 审核 -->
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/pagination/pagination.vue";
import {
  departmentList,
  departmentAdd,
  departmentUpdate,
  departmentDelete,
  departmentAudit,
  departmentUpAndDown,
} from "@/api/apiList/system-api";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      form: {
        page: 1,
        pageSize: 10,
        name: "",
        status: "",
        audit_status: "",
      },
      tableData: [],
      loading: true,
      isShow: false, // 添加/编辑弹窗
      isAdd: true,
      pageSize: 10,
      currentPage: 1,
      total: 10,
      type: "", // 权限
      partForm: {
        id: "",
        name: "",
      },
      rules: {
        name: [{ required: true, message: "请输入部门名称", trigger: "blur" }],
      },
      audit: false, // 审核弹窗
      auditForm: {
        audit_status: "",
        audit_remarks: "",
      },
      auditRules: {
        audit_status: [
          { required: true, message: "请选择审核状态", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    // 检索
    onSubmit() {
      this.form.page = 1;
      this.form.pageSize = 10;
      this.loading = true;
      this.handPartList();
    },
    // 重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 添加部门
    handPartAdd() {
      this.isAdd = true;
      this.partForm = {
        id: "",
        name: "",
      };
      this.isShow = true;
    },
    // 编辑部门
    handleClick(e) {
      this.isAdd = false;
      this.partForm = {
        id: this.tableData[e].id,
        name: this.tableData[e].name,
      };
      this.isShow = true;
    },
    // 添加/修改提交
    handPartSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.isAdd) {
            // 添加
            departmentAdd(this.partForm).then((res) => {
              if (res.result === "200") {
                this.isShow = false;
                this.$message.success({
                  message: res.data.info,
                  type: "success",
                });
                this.handPartList();
              }
            });
          } else {
            // 编辑
            departmentUpdate(this.partForm).then((res) => {
              if (res.result === "200") {
                this.isShow = false;
                this.$message.success({
                  message: res.data.info,
                  type: "success",
                });
                this.handPartList();
              } else if (res.result === 300019) {
                this.$message.warning({
                  message: res.msg,
                  type: "success",
                });
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    // 删除
    handPartDelete(e) {
      this.$confirm("确定删除该部门?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let obj = {
            id: e,
          };
          departmentDelete(obj).then((res) => {
            if (res.result === "200") {
              this.$message.success({
                message: res.data.info,
                type: "success",
              });
              this.handPartList();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 审核部门
    handAudit(e) {
      this.auditForm.id = this.tableData[e].id;
      this.audit = true;
    },
    // 审核提交
    handAuditSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          departmentAudit(this.auditForm).then((res) => {
            if (res.result === "200") {
              this.audit = false;
              this.$message.success({
                message: res.data.info,
                type: "success",
              });
              this.handPartList();
            }
          });
        } else {
          return false;
        }
      });
    },
    // 上/下线
    handUpAndDown(e) {
      let type = this.tableData[e].status === "上线" ? "下线" : "上线";
      this.$confirm("确定" + type + "该部门？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let obj = {
            id: this.tableData[e].id,
            status: type === "下线" ? "3" : "2",
          };
          departmentUpAndDown(obj).then((res) => {
            if (res.result === "200") {
              this.$message({
                type: "success",
                message: res.data.info,
              });
              this.handPartList();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    handSizeChange(e) {
      this.loading = true;
      this.pageSize = e;
      this.form.pageSize = e;
      this.handPartList();
    },
    handCurrentChange(e) {
      this.loading = true;
      this.currentPage = e;
      this.form.page = e;
      this.handPartList();
    },
    handPartList() {
      departmentList(this.form).then((res) => {
        if (res.result === "200") {
          res.data.data.forEach((item) => {
            switch (item.status) {
              case 1:
                item.status = "待上线";
                break;
              case 2:
                item.status = "上线";
                break;
              case 3:
                item.status = "下线";
                break;
            }
            switch (item.audit_status) {
              case 1:
                item.audit_status = "待审批";
                break;
              case 2:
                item.audit_status = "审批不通过";
                break;
              case 3:
                item.audit_status = "审批通过";
                break;
            }
          });
          this.tableData = res.data.data;
          this.total = res.data.total;
          this.loading = false;
        }
      });
    },
  },
  mounted() {
    this.type = this.$store.state.type;
    this.handPartList();
  },
};
</script>

<style lang="scss">
.department-delelte {
  margin-top: 10px;
}
</style>
